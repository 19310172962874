import React, { useState, useEffect } from "react";
import "./settings.css";
import Card from "../../images/card4.jpg";
import Close from "../../images/closewhite.png";
import Modal from "react-modal";
import Success from "../../images/approved.png";
import { KEY } from "../../api";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    width: "754px",
    border: "1px solid #C060F1",
    borderRadius: "19px",
  },
};
Modal.setAppElement("#root");

const Notification = () => {
  const answers = [
    {
      title: "Announcements",
      subTitle:
        " We’re pleased to inform you that your video has been successfully approved by our moderation team! Its now ready to be displayed on the selected billboard.",
    },
    {
      title: "Choose the Monitors",
      subTitle:
        "Selecting your region helps us provide the most accurate product options,",
    },
    {
      title: "Choose the number of spots",
      subTitle:
        "Selecting your region helps us provide the most accurate product options,",
    },
    {
      title: "Upload the video",
      subTitle:
        "Selecting your region helps us provide the most accurate product options,",
    },
    {
      title: "Get Approval From the operator",
      subTitle:
        "Selecting your region helps us provide the most accurate product options,",
    },
  ];
  const [post, setPost] = useState([]);
  const [postId, setPostId] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  let subtitle;
  function afterOpenModal() {
    subtitle.style.color = "#fff";
  }
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    handleSubmit();
  }, []);
  const handleSubmit = async () => {
    try {
      const response = await KEY.get(
        "/notification/getAll",

        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      ).then((response) => {
        setPost(response?.data);
      });
    } catch (error) {
      console.error("Error logout:", error);
    }
  };
  const handleSubmitForModal = async (id) => {
    try {
      const response = await KEY.post(
        "/notification",
        { id: id },
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      ).then((response) => {
        setPost(response?.data);
      });
    } catch (error) {
      console.error("Error logout:", error);
    }
  };
  if (!post) return null;
  return (
    post && (
      <div className="notification">
        {post?.map((item, index) => {
          return (
            <div key={item?.id}>
              <div className="notificationBlock">
                <img className="notificationImg" src={Card} alt="image" />
                <div className="notificationText">
                  <h3 onClick={openModal}>{item?.title}</h3>
                  <p>{item?.message}</p>
                </div>
              </div>
              <div className="notificationDivider"></div>{" "}
            </div>
          );
        })}

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="changeEmail">
            <button className="closebtn" onClick={closeModal}>
              <img src={Close} alt="close" />
            </button>
            <div
              className="notificationModal"
              ref={(_subtitle) => (subtitle = _subtitle)}
            >
              <img src={Success} alt="image" />
              <p>
                We’re pleased to inform you that your video has been
                successfully approved by our moderation team! It’s now ready to
                be displayed on the selected billboard..
              </p>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};

export default Notification;
