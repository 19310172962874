import React, { useState } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { getCodeConfirm } from "../../store/actions/main-action";
// import { selectMessage } from "../../store/selectors/main-selector";
// import { getCodeResent } from "../../store/actions/main-action";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
// import { select } from "redux-saga/effects";

const PhoneConfirm = () => {
  // const dispatch = useDispatch();
  // const selector = useSelector(selectMessage);

  // console.log(errorSelect);
  // console.log(sessionStorage.getItem("id"),);
  const navigate = useNavigate();

  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [statePhone, setStatePhone] = useState(null)
  const [stateEmail, setStateEmail] = useState(null)
  const [err, setError] = useState('')
    

    const handleSubmit = async (data) => {
      
        try {
          
            const response = await axios.post('https://testarmenia.site:8443/api/register/confirm', data, {
                headers: {
                    'Accept-Language': 'en',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true,
            });
            setConfirmationSuccess(true);
            setStatePhone(response.data?.phoneNumber)
            setStateEmail(response.data?.username)
            navigate("/signIn") 
        } catch (error) {
            console.error('Error confirming registration:', error);
            setError(error.response?.data?.message)
        }
    };

  const handleResentPasswordReset = async () => {
    try {
          
      const response = await axios.post('https://testarmenia.site:8443/api/register/resent', statePhone, {
          headers: {
              'Accept-Language': 'en',
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          withCredentials: true,
      });
      setConfirmationSuccess(true);
  
  } catch (error) {
      console.error('Error confirming registration:', error);
      setError(error.response?.data?.message)
  }

    // dispatch(getCodeResent("+" + selector.phoneNumber));
  };

  const handleResentEmail = async () => {
    try {
          
      const response = await axios.post('https://testarmenia.site:8443/api/register/resent', stateEmail, {
          headers: {
              'Accept-Language': 'en',
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          withCredentials: true,
      });
      setConfirmationSuccess(true);
   
     
  } catch (error) {
      console.error('Error confirming registration:', error);
      setError(error.response?.data?.message)
  }
    // dispatch(getCodeResent(selector.username));
  };
  // console.log(selector);
  return (
    <div className="signup">
      <div className="container">
        <Link to={`/`}>
          {" "}
          <img className="singllogo" src={logo} alt="logo" />
        </Link>
      </div>

      <div className="container signUpContent">
        <div className="signUpForm">
          <h1>We sent a code to your phone and email</h1>
          <span className="resetPass">
            Enter the 6 - digital verification code send to email and phone
            number
          </span>
          <Formik
            initialValues={{
              otpEmail: "",
              otpMobile: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.otpMobile) {
                errors.otpMobile = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(
                //   getCodeConfirm({
                //     otpEmail: values.otpEmail,
                //     otpMobile: values.otpMobile,

                //     customerId:  sessionStorage.getItem("id"),
                //   })
                // );
                handleSubmit({ otpEmail: values.otpEmail,
                  otpMobile: values.otpMobile,

                  customerId:  sessionStorage.getItem("id")})
                setSubmitting(false);
                resetForm();
 
               
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="formContainer">
                <Form className="form">
                  <label className="label-form">Code from mobile</label>
                  <Field
                    type="text"
                    name="otpMobile"
                    className="input-form"
                    placeholder="------"
                    maxLength="6"
                    autoComplete="off"
                  />
                  {errors.otpMobile && touched.otpMobile ? (
                    <div className="error">{errors.otpMobile}</div>
                  ) : null}

                  <Link
                    className="formlink"
                    onClick={handleResentPasswordReset}
                  >
                    Resend Code
                  </Link>
                  <label className="label-form"> Code from email</label>
                  <Field
                    type="text"
                    name="otpEmail"
                    className="input-form"
                    placeholder="------"
                    maxLength="6"
                    autoComplete="off"
                  />
                  {errors.otpEmail && touched.otpEmail ? (
                    <div className="error">{errors.otpEmail}</div>
                  ) : null}
                  <Link onClick={handleResentEmail} className="formlink">
                    Resend Code
                  </Link>
                  {err ? <p className="error">{err}</p>: null}
                  <button type="submit" className="button-form">
                    Submit
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PhoneConfirm;
