import React, { useState, useEffect } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Eye from "../../images/eye.png";
// import { useDispatch, useSelector } from "react-redux";
// import { getApiLogin } from "../../store/actions/main-action";
// import { selectLogin } from "../../store/selectors/main-selector";
// import { selectLoginError } from "../../store/selectors/main-selector";

import "react-phone-input-2/lib/style.css";
import axios from "axios";
const SignIn = () => {
  const [data, setData] = useState({});
  //   console.log(data)
  const [showPassword, setshowPassword] = useState(false);
  const navigate = useNavigate();
  const toggleChecked = () => setshowPassword((value) => !value);
  // const dispatch = useDispatch();
  // const select = useSelector(selectLogin);
  // const selectError = useSelector(selectLoginError);
  const location = useLocation();

  // console.log(select);
  // console.log(selectError);

  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [err, setErr] = useState("");
  // console.log(registrationSuccess);
  useEffect(() => {
    if (registrationSuccess) {
      navigate("/upload");
    }
  }, [registrationSuccess]);
  const handleSubmit = async (data) => {
  
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/login",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );
      sessionStorage.setItem("username", data.username);
      setRegistrationSuccess(true);
    } catch (error) {
      // alert(error.response?.data?.message || "An error occurred");
      setRegistrationSuccess(false);
      setErr(error?.message)
      // console.log(error?.message);
    }
  };

  return (
    <div className="signup">
      <div className="container">
        <Link to={`/`}>
          {" "}
          <img className="singllogo" src={logo} alt="logo" />
        </Link>
      </div>

      <div className="container signUpContent">
        <div className="signUpForm">
          <h1>Sign In</h1>
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};
              const passwordRegex =
                /^(?=.*[A-Z])(?=.*\d)(?=.*[@$.!%*?&])[A-Za-z\d@$.!%*?&]{6,}$/;

              if (!values.username) {
                errors.username = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.username
                )
              ) {
                errors.username = "Invalid email address";
              } else if (!values.password) {
                errors.password = "Required";
              } else if (values.password.length < 8) {
                errors.password = "Password must be 8 characters long.";
              } else if (!passwordRegex.test(values.password)) {
                errors.password =
                  "Password must contain at least one uppercase letter, one number, and one special character";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(getApiLogin(values));
                // setData(values)
                setSubmitting(false);
                resetForm();
                handleSubmit(values);
                // {
                //   registrationSuccess ? navigate("/upload") : <></>;
                // }
                // {
                //   selectError?.error === "Unauthorized" ? (
                //     <>null</>
                //   ) : (
                //     navigate("/upload")
                //   );
                // }
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="formContainer">
                <Form className="form">
                  <label className="label-form">Email</label>
                  <Field
                    type="email"
                    name="username"
                    className="input-form"
                    autoComplete="off"
                    placeholder="Enter your email"
                  />
                  {errors.username && touched.username ? (
                    <div className="error">{errors.username}</div>
                  ) : null}
                
                 

                  <div className="pass">
                    <label className="label-form">Password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="input-form"
                      placeholder="Enter your password"
                    />
                    {errors.password && touched.password ? (
                      <div className="error">{errors.password}</div>
                    ) : null}
                    <img src={Eye} alt="image" onClick={toggleChecked} />
                  </div>

                  <Link to={`/forgotpassword`} className="formlink">
                    Forgot your password ?
                  </Link>

                  {
                    err !== "" ? <div className="error">
                    User not exists
                  </div> : null
                  }
                  <button type="submit" className="button-form">
                    Sign In
                  </button>
                </Form>
              </div>
            )}
          </Formik>
          <p className="formButtom">
            Don’t have an account? <Link to={`/signUp`}>Sign Up</Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
