import React, { useState, useEffect } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Eye from "../../images/eye.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { useDispatch, useSelector } from "react-redux";
// import { getMessage } from "../../store/actions/main-action";
// import { selectMessage } from "../../store/selectors/main-selector";
// import { selectErrorMessage } from "../../store/selectors/main-selector";
import Modal from "react-modal";
import axios from "axios";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    border: "1px solid #C060F1",
    borderRadius: "20px",
    padding: "30px",
  },
};
Modal.setAppElement("#root");

function Signeup() {
  const [data, setData] = useState("");

  const [showPassword, setshowPassword] = useState(false);
  const [showconfirm, setConfirm] = useState(false);
  const toggleChecked = () => setshowPassword((value) => !value);
  const toggleComfirm = () => setConfirm((value) => !value);
  // const dispatch = useDispatch();
  // const select = useSelector(selectMessage);
  // const error = useSelector(selectErrorMessage);
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [err, setErr] = useState("");
  const [numId, setNumId] = useState(null);
  // console.log(registrationSuccess);
  useEffect(() => {
    if (registrationSuccess) {
      navigate("/phoneconfirm");
    } else {
      openModal();
    }
  }, [registrationSuccess]);
  const handleSubmit = async (data) => {
    // e.preventDefault();
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/register",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );
      console.log(response.data?.id);
      setNumId(response.data?.id);
      sessionStorage.setItem("id", response.data?.id);
      setRegistrationSuccess(true);
    } catch (error) {
      // alert(error.response?.data?.message || "An error occurred");
      setRegistrationSuccess(false);
      setErr(error?.message);
      // console.log(error?.message);
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#fff";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="signup">
      <div className="container">
        <Link to={`/`}>
          {" "}
          <img className="singllogo" src={logo} alt="logo" />
        </Link>
      </div>

      <div className="container signUpContent">
        <div className="signUpForm">
          <h1>Sign Up</h1>
          <Formik
            initialValues={{
              companyName: "",
              name: "",
              surname: "",
              email: "",
              password: "",
              phoneNumber: "",
              agreeWithTerms: "",
              passwordConfirm: "",
            }}
            validate={(values) => {
              const errors = {};
              const passwordRegex =
                /^(?=.*[A-Z])(?=.*\d)(?=.*[@$.!%*?&])[A-Za-z\d@$.!%*?&]{6,}$/;
              if (!values.companyName) {
                errors.companyName = "Required";
              } else if (!values.name) {
                errors.name = "Required";
              } else if (!values.surname) {
                errors.surname = "Required";
              } else if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              } else if (!values.phoneNumber) {
                errors.phoneNumber = "Required";
              } else if (!values.password) {
                errors.password = "Required";
              } else if (values.password.length < 8) {
                errors.password = "*Password must be 8 characters long.";
              } else if (!passwordRegex.test(values.password)) {
                errors.password =
                  "Password must contain at least one uppercase letter, one number, and one special character";
              } else if (values.password !== values.passwordConfirm) {
                errors.passwordConfirm = "Does not match";
              } else if (!values.agreeWithTerms) {
                errors.agreeWithTerms = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(getMessage(values));
                handleSubmit(values);
                // setData(values);
                setSubmitting(false);
                resetForm();
                // {
                //   data === "" ? openModal() : navigate("/phoneconfirm");
                // }
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="formContainer">
                <Form className="form">
                  <label className="label-form">Company Name</label>
                  <Field
                    type="text"
                    autoComplete="off"
                    name="companyName"
                    className="input-form"
                    placeholder="Enter your company name"
                  />
                  {errors.companyName && touched.companyName ? (
                    <div className="error">{errors.companyName}</div>
                  ) : null}
                  <label className="label-form">Name</label>
                  <Field
                    type="text"
                    name="name"
                    autoComplete="off"
                    className="input-form"
                    placeholder="Enter your name"
                  />
                  {errors.name && touched.name ? (
                    <div className="error">{errors.name}</div>
                  ) : null}
                  <label className="label-form">Surname</label>
                  <Field
                    type="text"
                    name="surname"
                    autoComplete="off"
                    className="input-form "
                    placeholder="Enter your surname"
                  />
                  {errors.surname && touched.surname ? (
                    <div className="error">{errors.surname}</div>
                  ) : null}
                  <label className="label-form">Email</label>
                  <Field
                    type="email"
                    name="email"
                    autoComplete="off"
                    className="input-form"
                    placeholder="Enter your email"
                  />
                  {errors.email && touched.email ? (
                    <div className="error">{errors.email}</div>
                  ) : null}

                  <div className="phonVerify">
                    <div className="verifyPhone">
                      <label htmlFor="phoneNumber" className="label-form ">
                        Phone
                      </label>
                      <Field name="phoneNumber" className="input-form">
                        {({ field }) => (
                          <PhoneInput
                            {...field}
                            country={"ae"}
                            onChange={(phone) =>
                              setFieldValue("phoneNumber", phone)
                            }
                          />
                        )}
                      </Field>
                      {errors.phoneNumber ? (
                        <div className="error">{errors.phoneNumber}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="pass">
                    <label className="label-form">Password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      autoComplete="off"
                      className="input-form"
                      placeholder="Enter your password"
                    />
                    <img src={Eye} alt="image" onClick={toggleChecked} />
                    {errors.password ? (
                      <div className="error">{errors.password}</div>
                    ) : null}
                  </div>
                  <div className="pass">
                    <label className="label-form">Confirm password</label>
                    <Field
                      type={showconfirm ? "text" : "password"}
                      name="passwordConfirm"
                      className="input-form "
                      placeholder="Confirm your password"
                    />
                    <img src={Eye} alt="image" onClick={toggleComfirm} />
                    {errors.passwordConfirm ? (
                      <div className="error">{errors.passwordConfirm}</div>
                    ) : null}
                  </div>
                  <label className="check-form">
                    <Field type="checkbox" name="agreeWithTerms" />
                    <span className="checkbox-container"></span> I agree with
                    terms of service and the privacy policy from LedSgO
                  </label>

                  <button type="submit" className="button-form">
                    Submit
                  </button>
                </Form>
              </div>
            )}
          </Formik>
          <p className="formButtom">
            Already have an account ? <Link to={`/signIn`}>Sign In</Link>{" "}
          </p>
          {err ? (
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <button onClick={closeModal}>X</button> */}
              <div className="findModalCards sizeModal">
              {err !== "" ? (
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                  {" "}
                
                   User already exists
              
                </h2>
    ) : null}
                <Link onClick={closeModal} className="findBtn">
                  Close
                </Link>
              </div>
            </Modal>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Signeup;
