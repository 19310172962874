import React, { useState, useEffect } from "react";
import Secondheader from "../components/SecondHeader/Secondheader";
import "./main.css";
import Meta from "../images/meta.png";
import Phone from "../images/phone.png";
import Mail from "../images/mail.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Header from '../components/header/Header'
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { getContactUs } from "../store/actions/main-action";
// import { selectContactUs } from "../store/selectors/main-selector";

const Conntact = () => {
  const [data, setData] = useState({});
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // const dispatch = useDispatch();
  // const select = useSelector(selectContactUs);


  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleSubmit = async (data) => {
    // e.preventDefault();
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/contact/request",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );
      
      setRegistrationSuccess(true);
    } catch (error) {
      // alert(error.response?.data?.message || "An error occurred");
      setRegistrationSuccess(false);
      // setErr(error?.message)
      // console.log(error?.message);
    }
  };
  return (
    <div className="uploadContent">
      <Header/>
      <div className="privicy">
        <div className="container">
          <div className="contactUs">
            <div className="talkwithUs">
              <h1>Let's talk with us</h1>
              <p>
                Questions, comments, or suggestions? Simply fill in the form and
                we’ll be in touch shortly.
              </p>
              <Link>
                {" "}
                <img src={Meta} alt="image" />
                1055 Arthur ave Elk Groot, 67. New Palmas South Carolina.{" "}
              </Link>
              <Link>
                <img src={Phone} alt="image" /> +1 234 678 9108 99
              </Link>
              <Link>
                <img src={Mail} alt="image" />
                Contact@moralizer.com
              </Link>
            </div>
            <div className="contactInputs">
              <Formik
                initialValues={{
                  email: "",
                  name: "",
                  surname: "",
                  message: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  } else if (!values.name) {
                    errors.name = "Required";
                  } else if (!values.name) {
                    errors.name = "Required";
                  } else if (!values.surname) {
                    errors.surname = "Required";
                  } else if (!values.message) {
                    errors.message = "Required";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setTimeout(() => {
                    // dispatch(getContactUs(values));
                    // setData({ values });
                    handleSubmit(values)
                    setSubmitting(false);
                    resetForm();
                  }, 400);
                }}
              >
                {({ isSubmitting, errors, touched, setFieldValue }) => (
                  <div className="contactInfo">
                    <Form className="contactForm">
                      <label className="contactLabel">Name</label>
                      <Field
                        type="text"
                        name="name"
                        className="nameInput"
                        autoComplete="off"
                        placeholder="Enter your name"
                      />
                      {errors.name && touched.name ? (
                        <div className="error">{errors.name}</div>
                      ) : null}
                      <label className="contactLabel">Surname</label>

                      <Field
                        type="text"
                        name="surname"
                        className="nameInput"
                        autoComplete="off"
                        placeholder="Enter your surname"
                      />
                      {errors.surname && touched.surname ? (
                        <div className="error">{errors.surname}</div>
                      ) : null}
                      <label className="contactLabel">Email</label>
                      <Field
                        type="email"
                        name="email"
                        autoComplete="off"
                        className="nameInput "
                        placeholder="Enter your email"
                      />
                      {errors.email && touched.email ? (
                        <div className="error">{errors.email}</div>
                      ) : null}
                      <label className="contactLabel">Your Message </label>
                      <Field
                        component="textarea"
                        className="textareaInput "
                        name="message"
                        placeholder="Your Message..."
                        rows="4"
                      />
                      {errors.message && touched.message ? (
                        <div className="error">{errors.message}</div>
                      ) : null}
                        {registrationSuccess ? <p className="successChange">Your message has been sent.</p>:  null}
                      <div className="cancelSave">
                        <button type="submit" className="contactSave">
                          Send Message
                        </button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>

  
            </div>
           
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Conntact;
