import React, {useState, useEffect} from 'react'
import "./settings.css";
import Card from '../../images/card1.jpg'
import Price from '../../images/price.png'
import Clock from '../../images/clock.png';
import Card2 from '../../images/Card3.png'
import axios from 'axios';
import { KEY } from '../../api';
const Announcment = () => {
  const [post, setPost] = useState([])
  useEffect(()=>{
    handleSubmit()
  },[])
  const handleSubmit = async () => {
    try {
    const response = await KEY.get(
          "/order/getAll?page=0&size=100",

          {
            headers: {
              "Accept-Language": "en",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
        
          setPost(response?.data)
        });
    } catch (error) {
      console.error("Error logout:", error);
    }
  };
console.log(post)
  return (
    <div className='announcment'>
      <h3> <span>!</span>For changes, please contact support.</h3>

      <div className='announcmentContent'>
      {post?.map((item, index) => {
          return (
          <div className='announcmentBox' key={item?.id}>
            <img className='announcmentBoxImage'  src={`https://testarmenia.site:8443/api/files?ledId=${item?.ledId?.id}`} alt='image' />
            <div className='announcmentText'>
                <div>
              <p>{item?.ledId?.billboardName}</p>
              <span className='announceSpan'>{item?.ledId?.description}</span>
              <div className='announcePrice'>
                <img className='announceFirst' src={Price} alt='image'/>
                <span>{item?.ledId?.price}$</span>
              </div>
              <div className='announcePrice'>
                <img className='announceSecond'  src={Clock} alt='image'/>
                <span>{item?.created}</span>
              </div>
              </div>
              <button className= {item?.status === "In progress" ? "announceGray" :   item?.status === "active" ? "announceGreen" : 
  "announceRed" }>{item?.status}</button>
              </div>
          </div>
          );
        })}
          {/* <div className='announcmentBox'>
          <img className='announcmentBoxImage' src={Card2} alt='image'/>
            <div className='announcmentText'>
                <div>
              <p>Zip Yard Dunlaoghaire</p>
              <span className='announceSpan'>Sheikh Mohammed bin Rashid Blvd - Downtown Dubai </span>
              <div className='announcePrice'>
                <img className='announceFirst' src={Price} alt='image'/>
                <span>500$</span>
              </div>
              <div className='announcePrice'>
                <img className='announceSecond'  src={Clock} alt='image'/>
                <span>10.22.2024</span>
              </div>
              </div>
              <button className='announceGray'>Completed</button>
              </div>
          </div> */}

      </div>

    </div>
  )
}

export default Announcment