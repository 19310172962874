import React, { useState, useEffect } from "react";
import logo from "../../images/logo.png";
import User from "../../images/user.png";
import "./secondheader.css";
import Setting from "../../images/settings.png";
import Anounce from "../../images/announce.png";
import Note from "../../images/notification.png";
import Logout from "../../images/logout_icon_transparent_inside_gradient.png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {API }from "../../api";
import { KEY } from "../../api";

const Secondheader = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);

  const handleSubmit = async () => {
    try {
      await API.post("/logout", {
        withCredentials: true,
      })
      .then((response) => {
        navigate("/");
        sessionStorage.removeItem("username");
      });
    } catch (error) {
      console.error("Error logout:", error);
    }
  };
  return (
    <div className="container">
      <div className="secondHeader">
        <div className="secondHeaderLogo">
          <Link to="/">
            <img className="logo" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="secondHeaderUser">
          <p className="userPTag">{sessionStorage.getItem("username")}</p>
          <div className="userName">
            <img
              className="headerUser"
              onClick={() => setUser((prev) => !prev)}
              src={User}
              alt="user"
            />
            <div className={`${user ? "settings" : "comentSettings"}`}>
              <Link to={`/tab/settings`}>
                <img className="headersettings" src={Setting} alt="user" />
                <p>Settings</p>
              </Link>
              <Link to={`/tab/announcment`}>
                <img className="headersettings" src={Anounce} alt="user" />
                <p>Announcements</p>
              </Link>
              <Link to={`/tab/notification`}>
                <img className="headersettings" src={Note} alt="user" />
                <p>Notifiactions</p>
              </Link>
              <div className="logout" onClick={handleSubmit}>
                <img className="headersettings" src={Logout} alt="user" />
                <p>Logout</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Secondheader;
