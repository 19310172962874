
import React from 'react'

import Secondheader from "../components/SecondHeader/Secondheader";
import Payment from '../components/LoadVideo/Payment';
import Header from '../components/header/Header';

const PaymentComplete = () => {
  return (
    <div className='videoBlock'>
    <Secondheader/>
 <Payment/>
  
</div>
  )
}

export default PaymentComplete
