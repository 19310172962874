import React, { useState } from 'react';
// import API from "../api";
import axios from 'axios';

const NewLoad = () => {
    const [ledId, setLedId] = useState('');
    const [video, setVideo] = useState(null);
    const [message, setMessage] = useState('');

    const handleFileChange = (e) => {
        setVideo(e.target.files[0]);
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!ledId || !video) {
            setMessage('Please provide both a valid UUID and a video file.');
            return;
        }
        console.log(video)
        try {
            const response = await axios.post('https://testarmenia.site:8443/api/order', {
                ledId, video
            });

            if (response.ok) {
                setMessage('Order submitted successfully!');
            } else {
                setMessage('Failed to submit order. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred while submitting the order.');
        }
    };

    return (
        <div>
            <h2>Order Form</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        LED ID (UUID):
                        <input
                            type="text"
                            value={ledId}
                            onChange={(e) => setLedId(e.target.value)}
                            placeholder="Enter UUID"
                            required
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Video File:
                        <input
                            type="file"
                            onChange={handleFileChange}
                            accept="video/*"
                            required
                        />
                    </label>
                </div>
                <button type="submit">Submit</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default NewLoad;
