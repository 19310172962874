import React, { useState, useEffect } from "react";
import "./settings.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Frame from "../../images/frame.png";
import { useNavigate, Link, useParams } from "react-router-dom";
import Close from "../../images/closewhite.png";
import Modal from "react-modal";
import Eye from "../../images/eye.png";
// import { useDispatch, useSelector } from "react-redux";
// import { getUserChange } from "../../store/actions/main-action";
// import { getUserConfirm } from "../../store/actions/main-action";
// import { selectuserChange } from "../../store/selectors/main-selector";
import axios from "axios";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "black",
    width: "468px",
    border: "1px solid #C060F1",
    borderRadius: "20px",
  },
};
Modal.setAppElement("#root");

const SetContact = () => {
  const [profile, setProfile] = useState(2);
  const [data, setData] = useState({});
  const [showPassword, setshowPassword] = useState(false);
  const toggleChecked = () => setshowPassword((value) => !value);
  const toggleProf = (index) => {
    setProfile(index);
  };
  let subtitle;
  const [erro, setErr]= useState('')
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTwoIsOpen, setTwoIsOpen] = useState(false);
  const [modalthreeIsOpen, setThreeIsOpen] = useState(false);
  const [modalfourIsOpen, setFourIsOpen] = useState(false);
  const [modalFiveIsOpen, setFiveIsOpen] = useState(false);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const select = useSelector(selectuserChange);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationchange, setChangeSuccess] = useState(false);
  const [registrationPhoneSuccess, setRegistrationPhoneSuccess] =
    useState(false);
  const [registrationPhonechange, setChangePhoneSuccess] = useState(false);
  const [err, seterr] = useState(null);
  useEffect(() => {
    if (registrationSuccess) {
      closeModal();
      openSecondModal();
    }
  }, [registrationSuccess]);
  useEffect(() => {
    if (registrationchange) {
      closeSecondModal();
      openthierdModal();
    } 
  }, [registrationchange]);
  useEffect(() => {
    if (registrationPhoneSuccess) {
      closefourthModal();
      openfivthModal();
    } else if (registrationPhonechange) {
      openfivthModal();
    }
  }, [ registrationPhoneSuccess]);
  useEffect(() => {
     if (registrationPhonechange) {
      openfivthModal();
    }
  }, [  registrationPhonechange]);


  const handleSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/user/credentials/change",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );

      setRegistrationSuccess(true);
    } catch (error) {
      if (error.response.status === 403 && error.response.status === 401) {
        navigate("/signIn");
      }
      setRegistrationSuccess(false);
      seterr(error.response?.data?.message);
    }
  };

  const handleChangeSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/user/credentials/confirm",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );

      setChangeSuccess(true);
    } catch (error) {
      setErr(error.response?.data?.message)
      if (error.response.status === 403 && error.response.status === 401) {
        navigate("/signIn");
      }
      setChangeSuccess(false);
    }
  };
  const handlePhoneSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/user/credentials/change",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );

      setRegistrationPhoneSuccess(true);
    } catch (error) {
      setErr(error.response?.data?.message)
      if (error.response.status === 403 && error.response.status === 401) {
        navigate("/signIn");
      }
      setRegistrationPhoneSuccess(false);
      seterr(error.response?.data?.message);
    }
  };

  const handlePhoneChangeSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/user/credentials/confirm",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );

      setChangePhoneSuccess(true);
    } catch (error) {
      setErr(error.response?.data?.message)
      if (error.response.status === 403 && error.response.status === 401) {
        navigate("/signIn");
      }
      setChangePhoneSuccess(false);
    }
  };

  function openModal() {
    setIsOpen(true);
  }
  function openSecondModal() {
    // navigate({ pathname: `/upload/${id}` });
    setTwoIsOpen(true);
    // setIsOpen(false);
  }
  function openthierdModal() {
    setThreeIsOpen(true);
  }
  function openfourthModal() {
    setFourIsOpen(true);
  }
  function openfivthModal() {
    setFiveIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#fff";
  }
  function closeSecondModal() {
    setTwoIsOpen(false);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function closethiethModal() {
    setThreeIsOpen(false);
  }

  function closefourthModal() {
    setFourIsOpen(false);
  }
  function closefivththModal() {
    setFiveIsOpen(false);
  }

  return (
    <div className={profile === 2 ? "content  active-content" : "content"}>
      <Formik
        initialValues={{
          otpEmailToChange: "",
          otpMobileToChange: "",
          otpMobile: null,
          otpEmail: null,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.otpEmailToChange) {
            errors.otpEmailToChange = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              values.otpEmailToChange
            )
          ) {
            errors.otpEmailToChange = "Invalid email address";
          } else if (!values.otpMobileToChange) {
            errors.otpMobileToChange = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            // dispatch(getUserConfirm(values));

            // setData({ values });
            setSubmitting(false);
            resetForm();
          }, 400);
        }}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => (
          <div className="profilecontact">
            <Form className="profilform">
              <div className="nameSurname">
                <div className="prof">
                  <label className="profilLabel">Email</label>
                  <Field
                    type="email"
                    name="otpEmailToChange"
                    autoComplete="off"
                    className="profilInput changeEmail"
                    placeholder="Enter your email"
                  />
                  <img src={Frame} alt="image" onClick={openModal} />
                  {errors.otpEmailToChange && touched.otpEmailToChange ? (
                    <div className="error">{errors.otpEmailToChange}</div>
                  ) : null}
                </div>
                <div className="prof">
                  <label htmlFor="otpMobileToChange" className="profilLabel">
                    Phone
                  </label>
                  <Field name="otpMobileToChange" className="profilInput">
                    {({ field }) => (
                      <PhoneInput
                        {...field}
                        country={"ae"}
                        onChange={(phone) =>
                          setFieldValue("otpMobileToChange", phone)
                        }
                      />
                    )}
                  </Field>
                  <img src={Frame} alt="image" onClick={openfourthModal} />
                  {errors.otpMobileToChange ? (
                    <div className="error">{errors.otpMobileToChange}</div>
                  ) : null}
                </div>
              </div>

              {/* <div className="cancelSave">
                <button type="reset" className="profilCancel">
                  Cancel
                </button>
                <button type="submit" className="profilSave">
                  Save
                </button>
              </div> */}
            </Form>
          </div>
        )}
      </Formik>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closeModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Change Email Address
          </h2>
          <p>
            Enter your password and the new email address where we will send an
            email with a confirmation code.
          </p>
          <Formik
            initialValues={{
              emailToChange: "",
              password: "",
              phoneNumberToChange: null,
            }}
            validate={(values) => {
              const errors = {};
              const passwordRegex =
                /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
              if (!values.emailToChange) {
                errors.emailToChange = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.emailToChange
                )
              ) {
                errors.emailToChange = "Invalid email address";
              } else if (values.password.length < 8) {
                errors.password = "*Password must be 8 characters long.";
              } else if (!passwordRegex.test(values.password)) {
                errors.password =
                  "Password must contain at least one uppercase letter, one number, and one special character";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(getUserChange(values));
                handleSubmit(values);
               
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="profilecontact">
                <Form className="profilform">
                  <div className="prof">
                    <label className="profilLabel">Password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="profilInput"
                      placeholder="Enter your password"
                    />
                    {errors.password && touched.password ? (
                      <div className="error">{errors.password}</div>
                    ) : null}
                    <img src={Eye} alt="image" onClick={toggleChecked} />
                  </div>
                  <div className="prof">
                    <label className="profilLabel">Email</label>
                    <Field
                      type="email"
                      name="emailToChange"
                      autoComplete="off"
                      className="profilInput "
                      placeholder="Enter your new email"
                    />

                    {errors.emailToChange && touched.emailToChange ? (
                      <div className="error">{errors.emailToChange}</div>
                    ) : null}
                  </div>
                  {err ? <div>{err}</div> : null}
                  <div className="cancelSave">
                    <button type="submit" className="profilSave">
                      Continue
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>

      <Modal
        isOpen={modalfourIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closefourthModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closefourthModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Change Phone Number
          </h2>
          <p>
            Enter your mobile phone number. We will send you a text message with
            a confirmation code. Once you enter the code on the next screen,
            your number will be verified.
          </p>
          <Formik
            initialValues={{
              emailToChange: null,
              password: "",
              phoneNumberToChange: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(
                //   getUserChange({
                //     emailToChange: null,
                //     password: values.password,
                //     phoneNumberToChange: values.phoneNumberToChange,
                //   })
                // );
                handlePhoneSubmit({
                  emailToChange: null,
                  password: values.password,
                  phoneNumberToChange: values.phoneNumberToChange,
                });
                // setData({ values });
                setSubmitting(false);
                // closefourthModal();
                // openfivthModal();
                // resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="profilecontact">
                <Form className="profilform">
                  <div className="prof">
                    <label className="profilLabel">Password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="profilInput"
                      placeholder="Enter your password"
                      autoComplete="off"
                    />
                    {errors.password && touched.password ? (
                      <div className="error">{errors.password}</div>
                    ) : null}
                    <img src={Eye} alt="image" onClick={toggleChecked} />
                  </div>
                  <div className="prof">
                    <label
                      htmlFor="phoneNumberToChange"
                      className="profilLabel"
                    >
                      Phone
                    </label>
                    <Field name="phoneNumberToChange" className="profilInput">
                      {({ field }) => (
                        <PhoneInput
                          {...field}
                          country={"ae"}
                          onChange={(phone) =>
                            setFieldValue("phoneNumberToChange", phone)
                          }
                        />
                      )}
                    </Field>

                    {errors.phoneNumberToChange ? (
                      <div className="error">{errors.phoneNumberToChange}</div>
                    ) : null}
                  </div>
                  {err ? <div className="error">{err}</div> : null}
                  <div className="cancelSave">
                    <button type="submit" className="profilSave">
                      Verify with SMS
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
      <Modal
        isOpen={modalTwoIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeSecondModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closeSecondModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Verify Your Email</h2>
          <p>
            We sent a verification code to both email addresses you provided.
          </p>
          <Formik
            initialValues={{
              otpEmailToChange: "",
              otpEmail: "",
              otpMobile: null,
              otpMobileToChange: null,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.otpEmailToChange) {
                errors.otpEmailToChange = "Required";
              } else if (!values.otpEmail) {
                errors.otpEmail = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(getUserConfirm(values));
                handleChangeSubmit(values);
                // setData({ values });
                setSubmitting(false);
                resetForm();
                // closeSecondModal();
                // openthierdModal();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="profilecontact">
                <Form className="profilform">
                  <div className="verification">
                    <label className="profilLabel">
                      Enter code from new email
                    </label>
                    <Field
                      type="text"
                      name="otpEmailToChange"
                      className="profilInput"
                      placeholder="------"
                      autoComplete="off"
                    />
                    {errors.otpEmailToChange && touched.otpEmailToChange ? (
                      <div className="error">{errors.otpEmailToChange}</div>
                    ) : null}
                  </div>
                  <div className="verification">
                    <label className="profilLabel">
                      Enter code from old email
                    </label>
                    <Field
                      type="text"
                      name="otpEmail"
                      className="profilInput"
                      placeholder="------"
                      autoComplete="off"
                    />
                    {errors.otpEmail && touched.otpEmail ? (
                      <div className="error">{errors.otpEmail}</div>
                    ) : null}
                  </div>
              {erro ? <div className="error">{erro}</div>: null}
                  <div className="cancelSave">
                    <button type="submit" className="profilSave">
                      Sent
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>

      <Modal
        isOpen={modalFiveIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closefivththModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closefivththModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Verify Phone Number
          </h2>
          <p>
            We sent a text message with the verification code to the number 971
            4 234 5678
          </p>
          <Formik
            initialValues={{
              otpMobile: "",
              otpMobileToChange: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.otpMobileToChange) {
                errors.otpMobileToChange = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(getUserConfirm(values));
                setData({ values });
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="profilecontact">
                <Form className="profilform">
                  <div className="verification">
                    <label className="profilLabel">
                      Enter Verification Code From New Number
                    </label>
                    <Field
                      type="text"
                      name="otpMobileToChange"
                      className="profilInput"
                      placeholder="------"
                      autoComplete="off"
                    />
                    {errors.otpMobileToChange && touched.otpMobileToChange ? (
                      <div className="error">{errors.otpMobileToChange}</div>
                    ) : null}
                  </div>
                  <div className="verification">
                    <label className="profilLabel">
                      Enter Verification Code From Old Number
                    </label>
                    <Field
                      type="text"
                      name="otpMobile"
                      className="profilInput"
                      placeholder="------"
                      autoComplete="off"
                    />
                    {errors.otpMobile && touched.otpMobile ? (
                      <div className="error">{errors.otpMobile}</div>
                    ) : null}
                  </div>

                  <div className="cancelSave">
                    <button type="submit" className="profilSave">
                      Verify
                    </button>
                  </div>
                </Form>
                <Link to={`/signIn`}>Sign In</Link>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
      <Modal
        isOpen={modalthreeIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closethiethModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="changeEmail">
          <button className="closebtn" onClick={closethiethModal}>
            <img src={Close} alt="close" />
          </button>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            Change Email Address
          </h2>
          <p>Your email address has been changed successfully.</p>

          <div className="profilecontact">
            <div className="profilform">
              <div className="cancelSave">
                <Link className="lastA" to={`/signIn`}>
                  <button
                    type="text"
                    className="profilSave"
                    onClick={closethiethModal}
                  >
                    Close
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SetContact;
