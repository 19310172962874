import React from 'react'
import Ai from '../../images/ai.png'
import AiBg from '../../images/aibg.png'
import { Link} from "react-router-dom";
import '../Banner/banner.css'

export const AiDriven = () => {
  return (
    <div className='newBanner'>
    <div  className='container'>


        <div className='bannerBottom'>
            <div className='bannerBottomText'>
            <h3>AI-Driven Display Selector</h3>
            <p>With AI technology, finding the best digital screens is easier than ever. Let us match you with displays that suit your goals perfectly.</p>
            <Link to={`/`}>Try Now</Link>
            </div>
            <div>
                <img src={Ai} alt='ai '/>
            </div>
        </div>
    </div>
    </div>
  )
}
