import React, { useState, useEffect } from "react";
import logo from "../../images/logo.png";
import "./header.css";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import MenuBar from "../../images/menu.png";
import Close from "../../images/closewhite.png";
 
import  Search from '../../images/search.png'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelect] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if(selected){
      searchParams.set("location", selected);
      setSearchParams(searchParams, { replace: true });
    }
  
  }, [selected]);
 
  const handelChang = (e) => {
    setSelect(e.target.value);
  };
  const handelTransfer = () => {
    navigate(`/upload?location=${selected}`);
  };

  return (
    <header>
      <div className="bigContainer ">
        <div className="container ">
          <div className="header">
            <Link to="/">
              <img className="logo" src={logo} alt="logo" />
            </Link>
            <img
              className="menuBar"
              src={MenuBar}
              alt="menu"
              onClick={() => setIsMenuOpen((prev) => !prev)}
            />
            <div
              className={
                isMenuOpen
                  ? "headerInputs "
                  : "headerInputs setIsMenuOpenmobile"
              }
            >
              <img
                className={isMenuOpen ? "menuClose closeActiv" : "menuClose "}
                onClick={() => setIsMenuOpen(false)}
                src={Close}
                alt="image"
              />
              {sessionStorage.getItem("username") ? (
                <div className="inputElement">
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={handelChang}
                    defaultValue={selected}
                  />{" "}
                  <span onClick={handelTransfer}> <img src={Search}/></span>{" "}
                </div>
              ) : null}

              <div className="sineInUp">
                {sessionStorage.getItem("username") ? (
                  <Link to={`/upload`} className="signUp">
                    {" "}
                    Upload Video
                  </Link>
                ) : null}

                <Link to={`/signIn`} className="signIn">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
