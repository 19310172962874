import React, { useState, useEffect } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Eye from "../../images/eye.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { useDispatch, useSelector } from "react-redux";
// import { getPasswordReset } from "../../store/actions/main-action";
// import { selectPasswordReset } from "../../store/selectors/main-selector";
import axios from "axios";
const ForgotPass = () => {
  const [data, setData] = useState("email");
  // const dispatch = useDispatch();
  // const select = useSelector(selectPasswordReset);
  const navigate = useNavigate();

  function handleChange(event) {
    setData(event.target.value);
  }

  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [err, setErr] = useState("");
  const [err1, setErr1] = useState("");
  useEffect(() => {
    if (confirmationSuccess) {
      if (data === "email") {
        navigate("/codefromemail");
      } else if (data === "phone") {
        navigate("/phoneVerification");
      }
    }
  }, [confirmationSuccess]);

  const handleSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/password/reset/request",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );
      setConfirmationSuccess(true);

    } catch (error) {
     
      setErr(error.response?.data?.message);
     
      
    }
  };

  return (
    <div className="signup">
      <div className="container">
        <Link to={`/`}>
          {" "}
          <img className="singllogo" src={logo} alt="logo" />
        </Link>
      </div>

      <div className="container signUpContent">
        <div className="signUpForm">
          <h1>Forgot Your Password ?</h1>
          <span className="resetPass">Reset password in two quick stepss</span>
          <div className="formContainer">
            <form onChange={(event) => handleChange(event)}>
              <div className="radiobtn">
                <label className="label-form">
                  <input
                    type="radio"
                    name="phoneNumber"
                    value="phone"
                    className="input-radio"
                  />
                  Phone
                </label>
                <label className="label-form">
                  <input
                    type="radio"
                    name="phoneNumber"
                    value="email"
                    className="input-radio"
                  />
                  Email
                </label>
              </div>
            </form>
          </div>
          <Formik
            initialValues={{
              email: null,
              phoneNumber: null,
            }}
            validate={(values) => {
              const errors = {};
              if (data === "email") {
                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
              } else if (data === "phoneNumber") {
                if (!values.phoneNumber) {
                  errors.phoneNumber = "Required";
                }
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(getPasswordReset(values));
                // setData({ values });
                handleSubmit(values);
                setSubmitting(false);
                resetForm();
                sessionStorage.setItem("email", values.email);
                sessionStorage.setItem("phone", values.phoneNumber);
               
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="formContainer">
                <Form className="form">
                  {data === "email" ? (
                    <>
                      <label className="label-form">Email</label>
                      <Field
                        type="email"
                        name="email"
                        autoComplete="off"
                        className="input-form"
                        placeholder="Enter your email"
                      />
                      {errors.email && touched.email ? (
                        <div className="error">{errors.email}</div>
                      ) : null}
                 {err   ? <div className="error">{err}</div> : null} 

                     
                      <button type="submit" className="button-form">
                        Reset Pasword
                      </button>
                   
                    </>
                  ) : (
                    <div className="verifyPhone">
                      <label htmlFor="phoneNumber" className="label-form ">
                        Phone
                      </label>
                      <Field name="phoneNumber" className="input-form">
                        {({ field }) => (
                          <PhoneInput
                            {...field}
                            country={"ae"}
                            onChange={(phone) =>
                              setFieldValue("phoneNumber", phone)
                            }
                          />
                        )}
                      </Field>

                      {errors.phoneNumber ? (
                        <div className="error">{errors.phoneNumber}</div>
                      ) : null}
   
                      {err ? <div className="error">{err}</div> : null}

                      <button type="submit" className="button-form">
                        Reset Pasword
                      </button>
                    </div>
                  )}
                </Form>
              </div>
            )}
          </Formik>
          <Link className="linkButtom" to={`/signIn`}>
            Back
          </Link>{" "}
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
