import axios from "axios";

export const API = axios.create({
  baseURL: "https://testarmenia.site:8443/api",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
});


export const KEY = axios.create({
  baseURL: "https://testarmenia.site:8443/api",
  headers: {
    "Accept-Language": "en",
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: true,
});
