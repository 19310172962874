import React, { useState } from "react";

import bannerImage from "../../images/bannerImag.png";
import bannerRight from "../../images/bg.png";

import "./banner.css";
import { Link } from "react-router-dom";
import Play from "../../images/play.png";

function Banner() {
  const [playbox, setPlayBox] = useState(true);

  return (
    <div className="banner">
      <div className="container">
        <div className="bannerText">
          <h1>
            DIGITIZE YOUR <br />
            VISI<span className="letterO"></span>N{" "}
            <b
              className="playBox" 
            
            >
              <span className="play">
                <img src={Play} alt="play" />
              </span>
            </b>
          </h1>
          {/* <Link to={`/`}>LET'S BEGIN </Link> */}
        </div>
      </div>
      <div className={`bannerImage  ${playbox ? null : "anim"} `}>
        <div className="animationFirstBlock">
        <div  className={`${playbox ? "bgAnim " : "bgAnim "}`}>
         
         </div>
        </div>
        <div className="animationBlock">
          <div>
        <div  className={`${playbox ? "bgAnim " : "bgAnim "}`}>
         
         </div>
         <div  className={`${playbox ? "bgAnim " : "bgAnim "}`}>
          
         </div>
       
        </div>
        <div >
        <div  className={`${playbox ? "bgAnim " : "bgAnim "}`}>
         
         </div>
         <div  className={`${playbox ? "bgAnim " : "bgAnim "}`}>
          
         </div>
         </div>
        </div>

        <img className="bannerI" src={bannerImage} alt="imag" />
        
      </div>
      <img className="bannerRight" src={bannerRight} alt="imag" />
    </div>
  );
}

export default Banner;
