import React, { useState, useEffect } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { getPasswordVerify } from "../../store/actions/main-action";
// import { selectPasswordVerify } from "../../store/selectors/main-selector";
import axios from "axios";
import "react-phone-input-2/lib/style.css";

const PhoneVerification = () => {
  // const dispatch = useDispatch();
  // const selector = useSelector(selectPasswordVerify);

  const navigate = useNavigate();

  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [statePhon, setStatePhone] = useState(null);
  useEffect(() => {
    if (confirmationSuccess) {
      navigate("/newpassword");
    }
  }, [confirmationSuccess]);
  const handleSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/password/reset/verify",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      ).then( (response)=> {
       
       
        sessionStorage.setItem('token', response.data?.token)
      });
      setConfirmationSuccess(true);
      setStatePhone(response.data?.phoneNumber);

      // console.log("Confirmation successful:", response.data);
    } catch (error) {
      // console.error("Error confirming registration:", error);
    }
  };
  const handleResentPasswordReset = async () => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/password/reset/request",
        {phoneNumber: sessionStorage.getItem('phone')}, 
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      ).then( (response)=> {
       
       
        sessionStorage.setItem('token', response.data.token)
      });;
    
      // console.log("Confirmation successful:", response.data);
    } catch (error) {
      // console.error("Error confirming registration:", error);
    }
  };
  return (
    <div className="signup">
      <div className="container">
        <Link to={`/`}>
          {" "}
          <img className="singllogo" src={logo} alt="logo" />
        </Link>
      </div>

      <div className="container signUpContent">
        <div className="signUpForm">
          <h1>We sent a code to your phone number</h1>
          <span className="resetPass">
            Enter the 6 - digital verification code send to ******* phone number
          </span>
          <Formik
            initialValues={{
              otpMobile: "",
              otpEmail: null,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.otpMobile) {
                errors.otpMobile = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(getPasswordVerify(values));
                handleSubmit(values);
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="formContainer">
                <Form className="form">
                  <label className="label-form">Code</label>
                  <Field
                    type="text"
                    name="otpMobile"
                    className="input-form"
                    placeholder="-------"
                    maxLength="6"
                    autoComplete="off"
                  />
                  {errors.otpMobile && touched.otpMobile ? (
                    <div className="error">{errors.otpMobile}</div>
                  ) : null}

                  <Link
                    onClick={handleResentPasswordReset}
                    className="formlink"
                  >
                    Resend Code
                  </Link>

                  <button type="submit" className="button-form">
                    Submit
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PhoneVerification;
