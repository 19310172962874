import React, { useState } from "react";
import "./settings.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Eye from "../../images/eye.png";
// import { useDispatch, useSelector } from "react-redux";
// import { getPassword } from "../../store/actions/main-action";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Close from "../../images/closewhite.png";
const SetPassword = () => {
  const [profile, setProfile] = useState(3);
  const [data, setData] = useState({});
 
  const [showPassword, setshowPassword] = useState(false);
  const [showconfirm, setConfirm] = useState(false);
  const [newPasswordd, setNewPassword] = useState(false);
  const toggleChecked = () => setshowPassword((value) => !value);
  const toggleComfirm = () => setConfirm((value) => !value);
  const toggleNew = () => setNewPassword((value) => !value);
  const toggleProf = (index) => {
    setProfile(index);
  };
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/user/password/change",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );

      setRegistrationSuccess(true);
    } catch (error) {
      setRegistrationSuccess(false);
      if (error.response.status === 403 && error.response.status === 401) {
        navigate("/signIn");
      }
   
    }
  };
  return (
    <div className={profile === 3 ? "content  active-content" : "content"}>
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          passwordconf: "",
        }}
        validate={(values) => {
          const errors = {};
          const passwordRegex =
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$.!%*?&])[A-Za-z\d@$.!%*?&]{6,}$/;
          if (!values.currentPassword) {
            errors.currentPassword = "Required";
          } else if (!values.newPassword) {
            errors.newPassword = "Required";
          } else if (!values.passwordconf) {
            errors.passwordconf = "Required";
          } else if (values.newPassword !== values.passwordconf) {
            errors.passwordconf = "dont match";
          } else if (values.currentPassword.length < 8) {
            errors.currentPassword = "Password must be 8 characters long.";
          } else if (values.newPassword.length < 8) {
            errors.newPassword = "Password must be 8 characters long.";
          } else if (values.passwordconf.length < 8) {
            errors.passwordconf = "Password must be 8 characters long.";
          } else if (!passwordRegex.test(values.currentPassword)) {
            errors.currentPassword =
              "Password must contain at least one uppercase letter, one number, and one special character";
          } else if (!passwordRegex.test(values.newPassword)) {
            errors.newPassword =
              "Password must contain at least one uppercase letter, one number, and one special character";
          } else if (!passwordRegex.test(values.passwordconf)) {
            errors.passwordconf =
              "Password must contain at least one uppercase letter, one number, and one special character";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            // dispatch(
            //   getPassword({
            //     currentPassword: values.currentPassword,
            //     newPassword : values.newPassword,
            //   })
            // );
            handleSubmit({
              currentPassword: values.currentPassword,
              newPassword: values.newPassword,
            });
            // setData({ values });
            setSubmitting(false);
            resetForm();
          }, 400);
        }}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => (
          <div className="profilecontact">
            <h3 className="changePasswordTite">Change Password</h3>
            <Form className="profilform">
              <div className="currentPass">
                <label className="profilLabelPass">Current Password</label>
                <Field
                  type={showPassword ? "text" : "password"}
                  name="currentPassword"
                  autoComplete="off"
                  className="profilInput "
                  placeholder="Enter your current password"
                />
                {errors.currentPassword && touched.currentPassword ? (
                  <div className="error">{errors.currentPassword}</div>
                ) : null}
                <img src={Eye} alt="image" onClick={toggleChecked} />
              </div>
              <div className="nameSurname">
                <div className="prof">
                  <label className="profilLabelPass">New Password </label>
                  <Field
                    type={newPasswordd ? "text" : "password"}
                    name="newPassword"
                    autoComplete="off"
                    className="profilInput "
                    placeholder="Enter your new password "
                  />
                  {errors.newPassword && touched.newPassword ? (
                    <div className="error">{errors.newPassword}</div>
                  ) : null}
                  <img src={Eye} alt="image" onClick={toggleNew} />
                </div>
                <div className="prof">
                  <label className="profilLabelPass">Confirm Password</label>
                  <Field
                    type={showconfirm ? "text" : "password"}
                    name="passwordconf"
                    autoComplete="off"
                    className="profilInput changeEmail"
                    placeholder="Confirm Password"
                  />
                  {errors.passwordconf && touched.passwordconf ? (
                    <div className="error">{errors.passwordconf}</div>
                  ) : null}
                  <img src={Eye} alt="image" onClick={toggleComfirm} />
                </div>
              </div>
              {registrationSuccess ? (
                <div className="successChange">Your password has been changed</div>
              ) : null}
              <div className="cancelSave">
                <button type="submit" className="profilChange">
                  Change Password
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SetPassword;
