import React, { useState, useEffect } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { getPasswordVerify } from "../../store/actions/main-action";
// import { getCodeResent } from "../../store/actions/main-action";
// import { selectPasswordVerify } from "../../store/selectors/main-selector";
// import { selectErrorCodeConfirm } from "../../store/selectors/main-selector";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const Codefromemail = () => {
  // const dispatch = useDispatch();
  // const selector = useSelector(selectPasswordVerify);
  // const errorSelect = useSelector(selectErrorCodeConfirm);
  const navigate = useNavigate();

  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [stateEmail, setStateEmail] = useState(null);
  const [otp, setOtp] = useState("");
  const [err, setErr] = useState(null);
  useEffect(() => {
    if (confirmationSuccess) {
      navigate("/newpassword");
    }
  }, [confirmationSuccess]);
  const handleSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/password/reset/verify",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      ).then( (response)=> {
       
       
        sessionStorage.setItem('token', response.data.token)
      });
      setConfirmationSuccess(true);

      setStateEmail(response.data?.username);
      // console.log("Confirmation successful:", response.data);
    } catch (error) {
      setErr(error?.message);
      // console.error("Error confirming registration:", error);
    }
  };
  // console.log(stateEmail)
  const handleResentPasswordReset = async () => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/password/reset/request",
       {phone: sessionStorage.getItem('phone')}, 
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      ).then( (response)=> {
        console.log(response);
        setOtp(response.data);
      })
  
      setConfirmationSuccess(true);
      console.log("Confirmation successful:", response.data);
    } catch (error) {
      setErr(error.response?.data?.message);
      console.error("Error confirming registration:", error);
    }
    // dispatch(getCodeResent(sessionStorage.getItem("email")));
  };
  // console.log(otp);

  return (
    <div className="signup">
      <div className="container">
        <Link to={`/`}>
          {" "}
          <img className="singllogo" src={logo} alt="logo" />
        </Link>
      </div>

      <div className="container signUpContent">
        <div className="signUpForm">
          <h1>We sent a code to your email</h1>
          <span className="resetPass">
            Enter the 6 - digital verification code send to{" "}
            {sessionStorage.getItem("email")}
          </span>
          <Formik
            initialValues={{
              otpEmail: "",
              otp: null,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.otpEmail) {
                errors.otpEmail = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(getPasswordVerify(values));
                handleSubmit(values);
                setSubmitting(false);
                resetForm();
                // {
                //   errorSelect === "" ? navigate("/newpassword") : <></>;
                // }
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="formContainer">
                <Form className="form">
                  <label className="label-form">Code</label>
                  <Field
                    type="text"
                    name="otpEmail"
                    className="input-form"
                    placeholder="-------"
                    maxLength="6"
                    autoComplete="off"
                  />
                  {errors.otpEmail && touched.otpEmail ? (
                    <div className="error">{errors.otpEmail}</div>
                  ) : null}
                  <Link
                    className="formlink"
                    onClick={handleResentPasswordReset}
                  >
                    Resend Code
                  </Link>
                  {err ? <div className="error">{err}</div>: null}
                  <button type="submit" className="button-form">
                    Submit
                  </button>{" "}
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Codefromemail;
