import React from "react";
import Advantige from "../components/Advantige/Advantige";
import Display from "../components/Display/Display";

import Header from "../components/header/Header";
import Whatisled from "../components/Whatisled/Whatisled";
import Answers from "../components/Accordion/Answers";
import Banner from "../components/Banner/Banner";
import { AiDriven } from "../components/AiDriven/AiDriven";

function Homepage() {
  return (
    <div>
      <Header />
      <Banner />
      <AiDriven/>
      <Display />
      <Whatisled />
      <Advantige />
      <Answers />
    </div>
  );
}

export default Homepage;
