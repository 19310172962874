import React, { useState } from "react";
import "./settings.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SetContact from "./SetContact";
import SetPassword from "./SetPassword";
// import { useDispatch, useSelector } from "react-redux";
// import { getUserProfile } from "../../store/actions/main-action";
// import { selectUserProfile } from "../../store/selectors/main-selector";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SetProfile = () => {
  const [profile, setProfile] = useState(1);
  const [data, setData] = useState({});
  const toggleProf = (index) => {
    setProfile(index);
  };
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const select = useSelector(selectUserProfile);

  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [succes, setSucces] = useState(false)

  const handleSubmit = async (data) => {
    // e.preventDefault();
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/user/profile",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );

      setRegistrationSuccess(true);
      setSucces(true)
    } catch (error) {
      // alert(error.response?.data?.message || "An error occurred");
      setRegistrationSuccess(false);
      if (error.response.status === 403 && error.response.status === 401) {
        navigate("/signIn");
      }
    }
  };
  return (
    <div className="contentTabs">
      <div className={"profileContent"}>
        <button
          className={profile === 1 ? "profile active " : "profile"}
          onClick={() => toggleProf(1)}
        >
          Profile
        </button>
        <button
          className={profile === 2 ? "profile active " : "profile"}
          onClick={() => toggleProf(2)}
        >
          Contact Information
        </button>
        <button
          className={profile === 3 ? "profile active " : "profile"}
          onClick={() => toggleProf(3)}
        >
          Password{" "}
        </button>
      </div>
      <div className={profile === 1 ? "content  active-content" : "content"}>
        <Formik
          initialValues={{
            companyName: "",
            name: "",
            surname: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.companyName) {
              errors.companyName = "Required";
            } else if (!values.name) {
              errors.name = "Required";
            } else if (!values.surname) {
              errors.surname = "Required";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              // dispatch(getUserProfile(values));
              handleSubmit(values);

              setSubmitting(false);
              resetForm();
            }, 400);
          }}
        >
          {({ isSubmitting, errors, touched, setFieldValue }) => (
            <div className="profilnames">
              <Form className="profilform">
                <label className="profilLabel">Company Name</label>
                <Field
                  type="text"
                  name="companyName"
                  className="profilInput largInput"
                  placeholder="Enter your company name"
                  autoComplete="off"
                />
                {errors.companyName && touched.companyName ? (
                  <div className="error">{errors.companyName}</div>
                ) : null}
                <div className="nameSurname">
                  <div className="prof">
                    <label className="profilLabel">Name</label>
                    <Field
                      type="text"
                      name="name"
                      className="profilInput"
                      placeholder="Enter your name"
                      autoComplete="off"
                    />
                    {errors.name && touched.name ? (
                      <div className="error">{errors.name}</div>
                    ) : null}
                  </div>
                  <div className="prof">
                    <label className="profilLabel">Surname</label>

                    <Field
                      type="text"
                      name="surname"
                      className="profilInput"
                      placeholder="Enter your surname"
                      autoComplete="off"
                    />
                    {errors.surname && touched.surname ? (
                      <div className="error">{errors.surname}</div>
                    ) : null}
                  </div>
                </div>
    {succes ? <p className="successChange">Your personal information has been changed.</p>: null}
                <div className="cancelSave">
                  <button type="reset" className="profilCancel">
                    Cancel
                  </button>
                  <button type="submit" className="profilSave">
                    Save
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
      {profile === 2 ? <SetContact /> : null}
      {profile === 3 ? <SetPassword /> : null}
    </div>
  );
};

export default SetProfile;
