import React, { useState, useEffect } from "react";
import "./signup.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Eye from "../../images/eye.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { useDispatch, useSelector } from "react-redux";
// import { getPasswordNew } from "../../store/actions/main-action";
// import { selectPasswordNew } from "../../store/selectors/main-selector";
import axios from "axios";
const EnterPassword = () => {
  const [data, setData] = useState({});
  //   console.log(data)
  const [showPassword, setshowPassword] = useState(false);
  const [showconfirm, setConfirm] = useState(false);
  const toggleChecked = () => setshowPassword((value) => !value);
  const toggleComfirm = () => setConfirm((value) => !value);
  const [err, setError] = useState('')
  // const dispatch = useDispatch();
  // const select = useSelector(selectPasswordNew);

  const navigate = useNavigate();
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);

  useEffect(() => {
    if (confirmationSuccess) {
      navigate("/signIn");
    }
  }, [confirmationSuccess]);
  const handleSubmit = async (data) => {
    try {
      const response = await axios.post(
        "https://testarmenia.site:8443/api/password/reset/confirm",
        data,
        {
          headers: {
            "Accept-Language": "en",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );
      setConfirmationSuccess(true);

      
    } catch (error) {
      console.error("Error confirming registration:", error);
      setError(error.response?.data?.message)
    }
  };

  return (
    <div className="signup">
      <div className="container">
        <Link to={`/`}>
          {" "}
          <img className="singllogo" src={logo} alt="logo" />
        </Link>
      </div>

      <div className="container signUpContent">
        <div className="signUpForm">
          <h1>Enter new password</h1>
          <Formik
            initialValues={{
              password: "",
              otp: "",
            }}
            validate={(values) => {
              const errors = {};
              const passwordRegex =
                /^(?=.*[A-Z])(?=.*\d)(?=.*[@$.!%*?&])[A-Za-z\d@$.!%*?&]{6,}$/;

              if (!values.password) {
                errors.password = "Required";
              } else if (values.password !== values.otp) {
                errors.otp = "Don`t match";
              } else if (values.password.length < 8) {
                errors.password = "Password must be 8 characters long.";
              } else if (!passwordRegex.test(values.password)) {
                errors.password =
                  "Password must contain at least one uppercase letter, one number, and one special character";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                // dispatch(getPasswordNew(values));
                // setData({ values });
                handleSubmit({ password: values.password,
                  otp:  sessionStorage.getItem('token')});
                setSubmitting(false);
                resetForm();
              }, 400);
            }}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <div className="formContainer">
                <Form className="form">
                  <div className="pass">
                    <label className="label-form">New password</label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="input-form"
                      placeholder="Enter your password"
                    />
                    <img src={Eye} alt="image" onClick={toggleChecked} />
                    {errors.password && touched.password ? (
                      <div className="error">{errors.password}</div>
                    ) : null}
                  </div>
                  <div className="pass">
                    <label className="label-form">Confirm password</label>
                    <Field
                      type={showconfirm ? "text" : "password"}
                      name="otp"
                      className="input-form "
                      placeholder="Confirm your password"
                    />
                    <img src={Eye} alt="image" onClick={toggleComfirm} />
                    {errors.otp && touched.otp ? (
                      <div className="error">{errors.otp}</div>
                    ) : null}
                  </div>
    {err ? <p className="error">{err}</p>: null}
                  <button type="submit" className="button-form">
                    Enter
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EnterPassword;
