import React , { useEffect}from 'react'
import { Link,Outlet, useLocation } from "react-router-dom"    

import Footer from '../components/Footer/Footer'

const Layout = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
       
        <Outlet/>
        <Footer/>
        
      
    </div>
  )
}

export default Layout
